import React from 'react';

const IconLogo = () => (
  <svg
    id="logo"
    xmlns="http://www.w3.org/2000/svg"
    width="26.9218mm"
    height="32.848mm"
    role="img"
    viewBox="0 0 159 194">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M 0.00,0.00
           C 0.00,0.00 18.00,17.00 18.00,17.00
             18.00,17.00 31.40,32.00 31.40,32.00
             31.40,32.00 32.00,39.00 32.00,39.00
             32.00,39.00 32.00,155.00 32.00,155.00
             32.00,155.00 31.40,162.00 31.40,162.00
             31.40,162.00 18.00,177.00 18.00,177.00
             18.00,177.00 0.00,194.00 0.00,194.00
             0.00,194.00 0.00,0.00 0.00,0.00 Z
           M 64.00,64.00
           C 64.00,64.00 103.00,25.00 103.00,25.00
             103.00,25.00 118.00,11.60 118.00,11.60
             118.00,11.60 125.00,11.00 125.00,11.00
             125.00,11.00 159.00,11.00 159.00,11.00
             159.00,11.00 141.04,29.99 141.04,29.99
             141.04,29.99 105.00,66.00 105.00,66.00
             105.00,66.00 92.00,79.00 92.00,79.00
             92.00,79.00 85.00,83.80 85.00,83.80
             85.00,83.80 78.00,79.00 78.00,79.00
             78.00,79.00 64.00,64.00 64.00,64.00 Z
           M 64.00,128.00
           C 64.00,128.00 78.00,115.01 78.00,115.01
             78.00,115.01 85.00,110.34 85.00,110.34
             85.00,110.34 92.00,115.01 92.00,115.01
             92.00,115.01 105.00,128.00 105.00,128.00
             105.00,128.00 140.00,163.00 140.00,163.00
             140.00,163.00 159.00,183.00 159.00,183.00
             159.00,183.00 126.00,183.00 126.00,183.00
             126.00,183.00 119.00,182.40 119.00,182.40
             119.00,182.40 104.00,169.00 104.00,169.00
             104.00,169.00 64.00,128.00 64.00,128.00 Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
